import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import Page from "./../components/Page";
import Section from "./../components/Section";
import NameCard from "./../components/NameCard";
import WithSideNav from "../components/WithSideNav";
import SEO from "../components/SEO";

function TeamPage() {
  const sideNavProps = [
    {
      title: "About Us",
      link: "/about"
    },
    {
      title: "Team & Collaborators",
      link: "/team"
    },
    {
      title: "Get Support",
      link: "/get-support"
    },
    {
      title: "Funding History",
      link: "/history"
    }
  ];

  return (
    <Page>
      <SEO title="Team | OHIF" />
      <Section>
        <WithSideNav sideNavProps={sideNavProps} title="Team">
          <h2 className="text-blue-100 text-3xl">Founding Members</h2>
          <div className="flex flex-row flex-wrap justify-start text-left">
            <NameCard
              name="Gordon J. Harris, Ph.D"
              email="harris@helix.mgh.harvard.edu"
              title="Principal Investigator & Director"
              affiliations={["Massachusetts General Hospital", "Yunu"]}
              className="w-full sm:w-1/2 lg:w-1/3 mb-8"
            >
              <StaticImage
                alt="Gordon J. Harris, Ph.D"
                className="rounded-full namecard-image"
                src="../images/team-gordon-harris.png"
              />
            </NameCard>

            <NameCard
              name="Erik Ziegler, Ph.D"
              email="erik.ziegler@yunu.io"
              title="Technical Advisor"
              affiliations={["Yunu"]}
              className="w-full sm:w-1/2 lg:w-1/3 mb-8"
            >
              <StaticImage
                alt="Erik Ziegler, Ph.D"
                className="rounded-full namecard-image"
                src="../images/team-erik-ziegler.png"
              />
            </NameCard>

            <NameCard
              name="Trinity Urban"
              email="trinity.urban@gmail.com"
              title="UX Advisor"
              affiliations={[]}
              className="w-full sm:w-1/2 lg:w-1/3 mb-8"
            >
              <StaticImage
                alt="Trinity Urban"
                className="rounded-full namecard-image"
                src="../images/team-trinity-urban.png"
              />
            </NameCard>

            <NameCard
              name="Rob Lewis"
              email="rob@radicalimaging.com"
              title="Technical Advisor"
              affiliations={["Radical Imaging"]}
              className="w-full sm:w-1/2 lg:w-1/3 mb-8"
            >
              <StaticImage
                alt="Rob Lewis"
                className="rounded-full namecard-image"
                src="../images/team-rob-lewis.png"
              />
            </NameCard>

            <NameCard
              name="Chris Hafey"
              email="chafey@gmail.com"
              title="Creator of Cornerstone.js, Technical Advisor"
              affiliations={[]}
              className="w-full sm:w-1/2 lg:w-1/3 mb-8"
            >
              <StaticImage
                alt="Chris Hafey"
                className="rounded-full namecard-image"
                src="../images/team-chris-hafey.png"
              />
            </NameCard>
          </div>

          <h2 className="phone-size:h2-heading-ps lg:h2-heading-lg mt-8">
            Team Members
          </h2>

          <div className="flex flex-row flex-wrap justify-start text-left ">
            <NameCard
              name="Ibrahim Mohamed"
              email="ibrahim.mohamed@radicalImaging.com"
              title="Developer"
              affiliations={["Radical Imaging"]}
              className="w-full sm:w-1/2 lg:w-1/3 mb-8 break-words pr-4"
            >
              <StaticImage
                alt="Ibrahim Mohamed"
                className="rounded-full namecard-image"
                src="../images/team-ibrahim.jpeg"
              />
            </NameCard>

            <NameCard
              name="Leonarado Campos"
              email=""
              title="Developer"
              affiliations={["Radical Imaging"]}
              className="w-full sm:w-1/2 lg:w-1/3 mb-8"
            >
              <StaticImage
                alt="Leonarado Campos"
                className="rounded-full namecard-image"
                src="../images/team-leonardo-campos.png"
              />
            </NameCard>

            <NameCard
              name="James Hanks"
              email="jhanks1@mgh.harvard.edu"
              title="Project Manager"
              affiliations={["Massachusetts General Hospital", "Yunu"]}
              className="w-full sm:w-1/2 lg:w-1/3 mb-8"
            >
              <StaticImage
                alt="James Hanks"
                className="rounded-full namecard-image"
                src="../images/team-james-hanks.png"
              />
            </NameCard>

            <NameCard
              name="Hua Li, M.D."
              email="hli11@partners.org"
              title="Developer"
              affiliations={["Massachusetts General Hospital", "Yunu"]}
              className="w-full sm:w-1/2 lg:w-1/3 mb-8"
            >
              <StaticImage
                alt="Hua Li, M.D."
                className="rounded-full namecard-image"
                src="../images/team-hua-li.png"
              />
            </NameCard>

            <NameCard
              name="Steve Pieper, Ph.D"
              email=""
              title="Advisor"
              affiliations={["Isomics"]}
              className="w-full sm:w-1/2 lg:w-1/3 mb-8"
            >
              <StaticImage
                alt="Steve Pieper, Ph.D"
                className="rounded-full namecard-image"
                src="../images/team-steve-pieper.png"
              />
            </NameCard>

            <NameCard
              name="Dan Rukas"
              email="drukas@mgh.harvard.edu"
              title="UX Design"
              affiliations={["Massachusetts General Hospital"]}
              className="w-full sm:w-1/2 lg:w-1/3 mb-8"
            >
              <StaticImage
                alt="Dan Rukas"
                className="rounded-full namecard-image"
                src="../images/team-dan-rukas.png"
              />
            </NameCard>

            <NameCard
              name="Alireza Sedghi, Ph.D"
              email="sedghi@accoladeimaging.com"
              title="Lead Architect"
              affiliations={["Accolade Imaging", "Radical Imaging"]}
              className="w-full sm:w-1/2 lg:w-1/3 mb-8"
            >
              <StaticImage
                alt="Alireza Sedghi, Ph.D"
                className="rounded-full namecard-image"
                src="../images/team-alireza-sedghi.png"
              />
            </NameCard>

            <NameCard
              name="Supriya Somarouthu, M.D."
              email=""
              title="Subject Matter Expert"
              affiliations={["Yunu"]}
              className="w-full sm:w-1/2 lg:w-1/3 mb-8"
            >
              <StaticImage
                alt="Supriya Somarouthu, M.D."
                className="rounded-full namecard-image"
                src="../images/team-supriya-somarouthu.png"
              />
            </NameCard>
          </div>
        </WithSideNav>
      </Section>
    </Page>
  );
}

export default TeamPage;
