import React from "react";

function NameCard({ name, email, title, affiliations, children, className }) {
  return (
    <div className={`${className}`}>
      {children}
      <h1 className="text-white text-lg">{name}</h1>
      <p className="text-blue-pale min-h-[25px]">{email}</p>
      <p className="text-white mt-5">{title}</p>
      <div className="mt-1">
        {affiliations.map((affiliation, index) => (
          <p key={index} className="text-blue-pale">
            {affiliation}
          </p>
        ))}
      </div>
    </div>
  );
}

export default NameCard;
